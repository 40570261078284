import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_c = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - C'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>C</h2>
     <LatQuotesIntro />
     <p><b>Cadit quaestio</b> - The matter admits of no further argument.</p>
<p><b>Cassetur billa (breve)</b> - Let the writ be quashed.</p>
<p><b>Casus fortuitus non est spectandus; et nemo tenetur divinare</b> - A fortuitous event is not to be foreseen and no person is bound to divine it.</p>
<p><b>Catalla reputantur inter minima in lege</b> - Chattels are considered in law among the minor things.</p>
<p><b>Causa proxima, non remota spectatur</b> - The immediate, and not the remote cause is to be considered.</p>
<p><b>Caveat emptor</b> - Let the purchaser beware.</p>
<p><b>Caveat venditor</b> - Let the seller beware.</p>
<p><b>Cepi corpus et est languidum</b> - I have taken the body and the prisoner is sick.</p>
<p><b>Cepi corpus et paratum habeo</b> - I have taken the body and have it ready.</p>
<p><b>Ceteris paribus</b> - Other things being equal.</p>
<p><b>Consensu</b> - Unanimously or, by general consent.</p>
<p><b>Consensus ad idem</b> - Agreement as to the same things.</p>
<p><b>Consuetudo loci observanda est</b> - The custom of the place is to be observed.</p>
<p><b>Contemporanea expositio est optima et fortissima in lege</b> - A contemporaneous exposition is best and most powerful in law.</p>
<p><b>Contra</b> - To the contrary.</p>
<p><b>Contra bonos mores</b> - Against good morals.</p>
<p><b>Contra non valentem agere nulla currit praescriptio</b> - No prescription runs against a person not able to act.</p>
<p><b>Contractus est quasi actus contra actum</b> - A contract is an act as it were against an act.</p>
<p><b>Conventio et modus vincunt legem</b> - A contract and agreement overcome the law.</p>
<p><b>Conventio privatorum non potest publico juri derogare</b> - An agreement of private persons cannot derogate from public right.</p>
<p><b>Coram Domino Rege</b> - In the presence of our Lord the King.</p>
<p><b>Coram non judice</b> - Before one who is not a judge.</p>
<p><b>Corpus</b> - Body.</p>
<p><b>Corpus delicti</b> - The body, i.e. the gist of crime.</p>
<p><b>Corpus humanum non recipit aestimationem</b> - A human body is not susceptible of appraisement. </p>
<p><b>Crescente malitia crescere debet et poena</b> - Vice increasing, punishment ought also to increase.</p>
<p><b>Crimen omnia ex se nata vitiat</b> - Crime vitiates every thing, which springs from it.</p>
<p><b>Crimen trahit personam</b> - The crime carries the person.</p>
<p><b>Cujus est dare, ejus est disponere</b> - He who has a right to give has the right to dispose of the gift.</p>
<p><b>Cujus est solum, ejus est usque ad coelam; et ad inferos</b> - He who owns the soil owns it up to the sky; and to its depth.</p>
<p><b>Cum duo inter se pugnantia reperiuntur in testamentis ultimum ratum est</b> - When two things repugnant to each other are found in a will, the last is to be confirmed.</p>
<p><b>Cursus curiae est lex curiae</b> - The practice of the court is the law of the court.</p>
<p><b>Custos morum</b> - A guardian of morals.</p>


   </Layout>
  )
}

export default LatQuotes_c
